import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Dialog from 'react-bootstrap-dialog'
const ModalSms = (props) => {
    var showModalSms = props.showModalSms;
    var idValidation = props.idValidation;
    var codeInterne = props.codeInterne;
    var telephone = props.telephone;
    var paysTel = props.paysTel;
    var dateRdv = props.dateRdv;
    var numeroSerie = props.numeroSerie;
    var typeRdv = props.typeRdv;
    var codePraticien = props.codePraticien;
    var email = props.email;
    var dialog = "";
    var code = "";

    Dialog.setOptions({
        defaultOkLabel: 'Oui',
        defaultCancelLabel: 'Non',
        primaryClassName: 'btn-danger',
        defaultButtonClassName: 'btn-primary'
    })
    const onCloseSms = e => {
        var url = props.url;
        var codeInterne = btoa(props.codeInterne);
        var numeroSerie = props.numeroSerie;
        dialog.show({
            title: 'Attention',
            body: 'Si vous quittez la fenêtre, votre rendez-vous sera annulé. Voulez vous vraiment quitter ?',
            bsSize: 'medium',
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                    axios.delete(url + "/rdv/cabinet/" + codeInterne + "?numero_serie=" + numeroSerie, {
                        headers: {
                            'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
                        }
                    })
                        .then((response) => {
                            toast("Le rendez-vous a été annulé.")
                            props.onHideSms()
                            showModalSms = false;
                        })
                        .catch(function (error) {
                            toast("Un problème est survenu pour supprimer le rendez-vous")
                        });

                })
            ],
            onHide: (dialog) => {
                dialog.hide()
            }
        })
    };
    const onRelanceSms = e => {
        let fields = {};
        fields['telephone'] = props.telephone;
        fields['numero_serie'] = numeroSerie;
        fields['paysTel'] = paysTel;
        axios.post(props.url + '/rdv/relance/sms/' + props.idValidation, fields, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then(function (response) {
                if (response.data.success == true) {
                    toast(response.data.data)
                } else {
                    toast(response.data.data)
                }
            })
            .catch(function (error) {
                toast("Un problème est survenu")
            });
    };

    const handleValidationSms = (e) => {
        e.preventDefault();
        let fields = {};
        fields['codeInterne'] = codeInterne;
        fields['code'] = code;
        fields['dateRdv'] = dateRdv;
        fields['telephone'] = telephone;
        fields['numero_serie'] = numeroSerie;
        fields['paysTel'] = paysTel;
        fields['typeRdv'] = typeRdv;
        fields['codePraticien'] = codePraticien;
        axios.post(props.url + '/rdv/valider/sms/' + props.idValidation, fields, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then(function (response) {
                if (response.data.success == true) {
                    toast(response.data.data)
                    props.handleValidationSms();
                } else {
                    toast(response.data.data)
                }
            })
            .catch(function (error) {
                toast("Un problème est survenu")
            });
    }
    const handleChange = e => {
        code = e.target.value;
    }
    return (
        <div>
            <Modal
                show={showModalSms}
                onHide={onCloseSms}
                size="md"
                centered
                backdrop="static"
            >
                <Modal.Header style={{
                    textAlign: "center",
                }}>
                    <Modal.Title style={{
                        textAlign: "center",
                        flex: 1
                    }}>Veuillez entrer le code reçu par sms au {props.telephone}</Modal.Title>
                    <Button variant="danger" size="sm" onClick={onCloseSms}>X</Button>
                </Modal.Header>
                <Modal.Body className="bodyModal">
                    <Form onSubmit={handleValidationSms}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextNom">
                            <Form.Label column sm="4">
                                Code :
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Modal.Footer style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            <Button variant="danger" id="btnAnnuler" onClick={onCloseSms}>
                                Annuler
                            </Button>
                            <Button className="btn btn-monortho" type="submit">
                                Valider
                            </Button>
                            <Button className="btn btnRelance" onClick={onRelanceSms}>
                                Renvoyer
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            <Dialog ref={(component) => { dialog = component }} />
        </div >
    )
}
export default ModalSms;