
import dateFormat from 'dateformat';
import React from 'react';
import ModalSms from './ModalSms';
import { i18n } from "dateformat";
import Horaire from './Horaire';
import AccordionJour from './AccordionJour';
import { Navigate } from 'react-router-dom';
import InfoModal from './InfoModal';
import { Col, Row, Button, Accordion } from 'react-bootstrap';
import '../styles/Planning.css'
import ReactLoading from 'react-loading';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Planning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showLigne: false,
            dispo: 0,
            showModalSms: false,
            codePraticien: this.props.codePraticien,
            typeRdv: this.props.typeRdv,
            dateRdv: "",
            codeInterne: "",
            telephone: "",
            jourDispo: this.props.jourDispo,
            nbJourNonDispo: 0,
            idValidation: 0,
            numeroSerie: this.props.numeroSerie,
            url: this.props.url,
            intervalle: this.props.intervalle,
            RdvConfirme: false,
            dateProchainRDVAffichage: "",
            prochainePriseRDV: "",
            soft: this.props.soft,
            body: [],
            dateJour: "",
            notice: this.props.notice,
        }
        this.tabHeader = [];
        this.headerTab = [];
        this.codePraticien = this.props.codePraticien;
        this.typeRdv = this.props.typeRdv;
        this.prochainePriseRDV = this.props.prochainePriseRDV;
        i18n.dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
        i18n.monthNames = ["janv.", "févr.", "mar.", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov", "déc.", "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre",];

        this.date = new Date();
        this.tabHeader = [];
        this.handleValidationModal = this.handleValidationModal.bind(this);
        this.listePlagesDispo = this.listePlagesDispo.bind(this);
        this.handleValidationSms = this.handleValidationSms.bind(this);
        this.onHideSms = this.onHideSms.bind(this);


    }
    handleValidationModal(idValidation, telephone, paysTel, codeInterne) {
        this.setState({ idValidation: idValidation, telephone: telephone, paysTel: paysTel, codeInterne: codeInterne }, function stateUpdateComplete() {
            this.setState({ showModalSms: true })
            toast.success("Un sms vous a été envoyé");
            this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
        });
    }
    refreshHoraire() {
        this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
    }
    onHideSms() {
        this.setState({ showModalSms: false }, function stateUpdateComplete() {
            this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
        });
    }
    handleValidationSms() {
        this.setState({ showModalSms: false, RdvConfirme: true })
    }

    getInfo(codePraticien, typeRdv, soft, delaiPremierRdv, notice) {
        this.verifPlages = false;
        this.state.jourDispo = this.props.jourDispo;
        this.setState({ 'notice': notice });
        var dateD = dateFormat(new Date(), "yyyy-mm-dd")
        var dateDelai = new Date()
        if (delaiPremierRdv > 0) {
            dateDelai.setDate(dateDelai.getDate() + delaiPremierRdv);
            dateDelai = dateFormat(dateDelai, "yyyy-mm-dd");
            if (dateDelai > dateD) {
                dateD = dateDelai;
            }
        }
        axios.get(this.props.url + "/rdv/dispo/premier?date=" + dateD + "&codePraticien=" + codePraticien + "&typeRdv=" + typeRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                if (response.data == false) {
                    this.setState({ 'showPlanning': false });
                    this.aucuneDispo()
                } else {
                    var dateProchaine = response.data.premiereDateDispo.data.data[0]['date'];
                    if (dateProchaine == '')
                        dateProchaine = null;
                    if (dateProchaine !== null)
                        this.prochainePriseRDV = new Date(dateProchaine.replace(/-/g, "/"));
                    this.dateDebutFormat = dateFormat(this.prochainePriseRDV, "yyyy-mm-dd");
                    var jourDispo = response.data.jourDispo;
                    this.setState({ 'soft': soft, 'delai': delaiPremierRdv, 'dateDelai': dateDelai, 'url': this.props.url, 'prochainePriseRDV': this.prochainePriseRDV, 'dispo': this.prochainePriseRDV, 'jourDispo': jourDispo, 'nbJourNonDispo': this.state.intervalle - this.state.jourDispo.length }, function stateUpdateComplete() {
                        this.plageDispo();
                    }.bind(this));
                    this.setState({ 'codePraticien': codePraticien, 'typeRdv': typeRdv });
                }
                this.forceUpdate()
            })
            .catch(function (error) {
            });



    }
    dateDiff(date1, date2) {
        var diff = {}                           // Initialisation du retour
        var tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes

        tmp = Math.floor((tmp - diff.sec) / 60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;                    // Extraction du nombre de minutes

        tmp = Math.floor((tmp - diff.min) / 60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;                   // Extraction du nombre d'heures

        tmp = Math.floor((tmp - diff.hour) / 24);   // Nombre de jours restants
        diff.day = tmp;

        return diff;
    }
    handleSubmitHeure = (dateRdv, codePraticien, typeRdv) => {
        this.dateRdv = dateRdv;
        this.codePraticien = codePraticien;
        this.typeRdv = typeRdv;
        this.setState({ 'codePraticien': codePraticien, 'typeRdv': typeRdv, 'dateRdv': dateRdv }, function stateUpdateComplete() {
            this.setState({ 'show': true });
        });
    }
    onClose = () => {
        this.setState({ 'show': false });
    }
    listePlagesDispo = (e) => {
        this.disabledMoveDatePrec = false;
        this.disabledMoveDateNext = false;
        this.verifPlages = true;
        var dateProchaine = dateFormat(this.state.prochainePriseRDV, "yyyy-mm-dd");
        this.dateDebutFormat = dateProchaine;
        this.plageDispo();
    }
    plageDispo() {
        var typeRdv = this.props.typeRdv;
        var codePraticien = this.props.codePraticien;
        var dateD = new Date(this.dateDebutFormat.replace(/-/g, "/"));
        var dateF = dateD.setDate(dateD.getDate() + 30);
        this.dateFinFormat = dateFormat(dateF, "yyyy-mm-dd");
        this.state.loading = true;
        var obj = this;
        obj.state.loading = false;
        axios.get(this.state.url + "/rdv/dispo/liste?dateDebut=" + this.dateDebutFormat + "&dateFin=" + this.dateFinFormat + "&codePraticien=" + codePraticien + "&typeRdv=" + typeRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                if (this.state.prochainePriseRDV == null || this.state.prochainePriseRDV == '')
                    this.aucuneDispo();
                this.tabDate = response.data;
                this.bodyA = [];
                for (let index = 0; index < this.tabDate.items.length; index++) {
                    const element = this.tabDate.items[index];
                    this.bodyA.push(<AccordionJour
                        items={index}
                        dateJour={element.date}
                        key={element.date}
                        codePraticien={obj.state.codePraticien}
                        typeRdv={obj.state.typeRdv}
                        heure={element.heure}
                        handleSubmitHeure={this.handleSubmitHeure}
                    />);

                }
                obj.setState({
                    body: this.bodyA
                })
            })
            .catch(function (error) {
                toast('Un problème est survenu.')
                obj.state.loading = false;
            });
    }
    aucuneDispo() {
        this.disabledMoveDatePrec = true;
        this.disabledMoveDateNext = true;
        this.headerTab = [];
        this.headerTab.push(<th className="align-text">Aucun créneau de libre n'a été trouvé, veuillez contacter le cabinet pour prendre rendez-vous.</th>);
        this.bodyTrTab = [];
        this.state.loading = false;
    }
    plusDispo() {
        this.disabledMoveDatePrec = false;
        this.disabledMoveDateNext = true;
        this.headerTab = [];
        this.bodyTrTab = [];
        this.bodyTrTab.push(<tr ><td colSpan={this.state.intervalle}> Il n'y a plus de disponibilité.</td></tr>);
        this.state.loading = false;
    }
    render() {
        return (<>
            <Accordion defaultActiveKey="0">
                {this.state.body}
            </Accordion>
            <InfoModal
                handleShow="false"
                handleValidationModal={this.handleValidationModal}
                show={this.state.show} onClose={this.onClose}
                dateRdv={this.state.dateRdv}
                codePraticien={this.state.codePraticien}
                typeRdv={this.state.typeRdv}
                numeroSerie={this.state.numeroSerie}
                url={this.state.url}
                refreshHoraire={this.refreshHoraire}
                soft={this.state.soft}
                notice={this.state.notice}
            />
            <ModalSms
                showModalSms={this.state.showModalSms}
                onHideSms={this.onHideSms}
                handleValidationSms={this.handleValidationSms}
                dateRdv={this.state.dateRdv}
                telephone={this.state.telephone}
                paysTel={this.state.paysTel}
                typeRdv={this.state.typeRdv}
                idValidation={this.state.idValidation}
                numeroSerie={this.state.numeroSerie}
                codeInterne={this.state.codeInterne}
                url={this.state.url}
                codePraticien={this.state.codePraticien}
            />

            <ToastContainer position="top-center" />
            {this.state.RdvConfirme ? (
                <Navigate to='/RdvConfirme' />
            ) : <div></div>
            }
        </>
        )

    }
}
export default Planning
