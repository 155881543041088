
import { Col, Row, Button } from 'react-bootstrap';

const Horaire = (props) => {
    const date = props.date;
    const heure = props.heure;
    const codePraticien = props.codePraticien;
    const typeRdv = props.typeRdv;
    const dateRdv = date + "T" + heure;//+ ':00';
    return (
        <div>
            <Button className="btn btnHoraire" onClick={props.handleSubmitHeure.bind(this, dateRdv, codePraticien, typeRdv)}  >{heure}</Button>
        </div>
    )
}
export default Horaire;