
import dateFormat from 'dateformat';
import React from 'react';
import ModalSms from './ModalSms';
import { i18n } from "dateformat";
import Horaire from './Horaire';
import { Navigate } from 'react-router-dom';
import InfoModal from './InfoModal';
import { Col, Row, Button, c } from 'react-bootstrap';
import '../styles/Planning.css'
import ReactLoading from 'react-loading';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Planning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showLigne: false,
            dispo: 0,
            showModalSms: false,
            codePraticien: this.props.codePraticien,
            typeRdv: this.props.typeRdv,
            dateRdv: "",
            codeInterne: "",
            telephone: "",
            jourDispo: this.props.jourDispo,
            nbJourNonDispo: 0,
            idValidation: 0,
            numeroSerie: this.props.numeroSerie,
            url: this.props.url,
            intervalle: this.props.intervalle,
            RdvConfirme: false,
            dateProchainRDVAffichage: "",
            prochainePriseRDV: "",
            soft: this.props.soft,
            dateDelai: dateFormat(new Date(), "yyyy-mm-dd"),
            delai: 0,
            notice: this.props.notice,
        }
        this.loading = false;
        this.tabHeader = [];
        this.headerTab = [];
        this.testDate = new Date();
        this.testDate = dateFormat(this.testDate, "d dddd mmmm yyyy");
        this.disabledMoveDatePrec = false;
        this.disabledMoveDateNext = false;
        this.codePraticien = this.props.codePraticien;
        this.typeRdv = this.props.typeRdv;
        this.prochainePriseRDV = this.props.prochainePriseRDV;
        this.plusMoins = "Voir plus";
        this.onPrecedentClick = this.onPrecedentClick.bind(this);
        this.onSuivantClick = this.onSuivantClick.bind(this);
        i18n.dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
        i18n.monthNames = ["janv.", "févr.", "mar.", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov", "déc.", "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre",];
        this.tabFinale = ""
        this.date = new Date();
        this.tabDate = {}
        this.tabHeader = [];
        this.verifPlages = false;
        // this.affichage(this.date);
        this.verifJourDispo = this.verifJourDispo.bind(this);
        this.onHideSms = this.onHideSms.bind(this);
        this.premiereDateDispo = this.premiereDateDispo.bind(this);
        this.handleValidationSms = this.handleValidationSms.bind(this);
        this.handleValidationModal = this.handleValidationModal.bind(this);
        this.refreshHoraire = this.refreshHoraire.bind(this);
        this.handleSubmitHeure = this.handleSubmitHeure.bind(this);
        this.onClickVoirPlusMoins = this.onClickVoirPlusMoins.bind(this);
        this.listePlagesDispo = this.listePlagesDispo.bind(this);

    }
    handleValidationModal(idValidation, telephone, paysTel, codeInterne) {
        this.setState({ idValidation: idValidation, telephone: telephone, paysTel: paysTel, codeInterne: codeInterne }, function stateUpdateComplete() {
            this.setState({ showModalSms: true })
            toast.success("Un sms vous a été envoyé");
            this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
        });
    }
    refreshHoraire() {
        this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
    }
    onHideSms() {
        this.setState({ showModalSms: false }, function stateUpdateComplete() {
            this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice);
        });
    }
    handleValidationSms() {
        this.setState({ showModalSms: false, RdvConfirme: true })
    }

    getInfo(codePraticien, typeRdv, soft, delaiPremierRdv, notice) {
        this.verifPlages = false;
        this.state.jourDispo = this.props.jourDispo;
        this.setState({ 'notice': notice });
        var dateD = dateFormat(new Date(), "yyyy-mm-dd")
        var dateDelai = new Date()
        if (delaiPremierRdv > 0) {
            dateDelai.setDate(dateDelai.getDate() + delaiPremierRdv);
            dateDelai = dateFormat(dateDelai, "yyyy-mm-dd");
            if (dateDelai > dateD) {
                dateD = dateDelai;
            }
        }
        axios.get(this.props.url + "/rdv/dispo/premier?date=" + dateD + "&codePraticien=" + codePraticien + "&typeRdv=" + typeRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                if (response.data == false) {
                    this.setState({ 'showPlanning': false });
                    this.aucuneDispo()
                } else {
                    if (response.data.premiereDateDispo.data.error != undefined) {
                        this.disabledMoveDatePrec = true;
                        this.disabledMoveDateNext = true;
                        toast(response.data.premiereDateDispo.data.error)
                        this.aucuneDispo();
                    } else {
                        var dateProchaine = response.data.premiereDateDispo.data.data[0]['date'];
                        if (dateProchaine == '')
                            dateProchaine = null;
                        var jourDispo = response.data.jourDispo;
                        this.plusMoins = "Voir plus"
                        var dateJ = new Date()
                        dateJ.setDate(dateJ.getDate() + 6);

                        this.disabledMoveDatePrec = false;
                        this.disabledMoveDateNext = false;
                        var dateD = new Date()
                        this.dateDebutFormat = dateFormat(dateD, "yyyy-mm-dd");
                        if (dateDelai > this.dateDebutFormat) {
                            this.dateDebutFormat = dateDelai;
                        }
                        if (dateProchaine !== null) {
                            this.prochainePriseRDV = new Date(dateProchaine.replace(/-/g, "/"));
                            this.prochainePriseRDVFormat = dateFormat(this.prochainePriseRDV, "yyyy-mm-dd");
                            if (dateDelai < this.prochainePriseRDVFormat) {
                                dateDelai = this.prochainePriseRDVFormat;
                            }
                        }
                        this.setState({ 'soft': soft, 'delai': delaiPremierRdv, 'dateDelai': dateDelai, 'url': this.props.url, 'prochainePriseRDV': this.prochainePriseRDV, 'dispo': this.prochainePriseRDV, 'jourDispo': jourDispo, 'nbJourNonDispo': this.state.intervalle - this.state.jourDispo.length }, function stateUpdateComplete() {
                            this.plageDispo();
                        }.bind(this));
                        this.setState({ 'codePraticien': codePraticien, 'typeRdv': typeRdv });
                    }
                }
                this.forceUpdate()
            })
            .catch(function (error) {
            });



    }
    premiereDateDispo() {
        this.verifPlages = false;
        this.loading = true;
        var obj = this;
        var dateDelai = this.state.dateDelai;
        if (dateDelai > this.dateDebutFormat) {
            this.dateDebutFormat = dateDelai;
        }
        axios.get(this.state.url + "/rdv/dispo/premier?date=" + this.dateDebutFormat + "&codePraticien=" + this.state.codePraticien + "&typeRdv=" + this.state.typeRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                var dateProchaine = response.data.premiereDateDispo.data.data[0]['date'];
                if (dateProchaine !== null && dateProchaine !== "") {
                    var datePro = new Date(dateProchaine.replace(/-/g, "/"));
                    this.setState({ 'dateDelai': dateDelai, dateProchainRDVAffichage: dateFormat(datePro, "d mmmm yyyy"), prochainePriseRDV: datePro }, function stateUpdateComplete() {
                        this.disabledMoveDateNext = false;
                        this.dateDebutJ = new Date(this.dateDebutFormat.replace(/-/g, "/"))
                        var dateD = this.dateDiff(this.dateDebutJ, this.state.prochainePriseRDV);
                        if (dateD.day >= 7) {
                            this.verifPlages = false
                        } else {
                            this.verifPlages = true
                        }
                        this.plageDispo();
                        obj.loading = false;
                    }.bind(this));
                    obj.loading = false;
                } else {
                    obj.plusDispo();
                    this.forceUpdate()
                    obj.disabledMoveDateNext = true;
                    obj.loading = false;
                }
            })
            .catch(function (error) {
                obj.loading = false;
            });
    }
    dateDiff(date1, date2) {
        var diff = {}                           // Initialisation du retour
        var tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes

        tmp = Math.floor((tmp - diff.sec) / 60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;                    // Extraction du nombre de minutes

        tmp = Math.floor((tmp - diff.min) / 60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;                   // Extraction du nombre d'heures

        tmp = Math.floor((tmp - diff.hour) / 24);   // Nombre de jours restants
        diff.day = tmp;

        return diff;
    }
    onPrecedentClick() {
        this.loading = true;
        //.replace(/-/g, "/")
        this.dateDebut = new Date(this.dateDebut);
        var dateP = this.dateDebut;
        var datePWeek = dateP.setDate(dateP.getDate() - this.state.intervalle)
        var dateprecedentWeek = dateFormat(datePWeek, "yyyy-mm-dd");
        this.verifPlages = true;
        var dateFin = new Date(this.dateDebutFormat);
        this.determineDateDebut(dateFin);
        this.plageDispo();
        this.dateDebut = dateprecedentWeek;
        this.disabledMoveDateNext = false;
    }
    onSuivantClick() {
        this.loading = true;
        this.dateDebut = new Date(this.dateDebut);
        this.dateDebut.setDate(this.dateDebut.getDate() + this.state.intervalle);
        var dateDebutWeek = dateFormat(this.dateDebut, "yyyy-mm-dd");
        var dateP = this.dateDebut;
        var dateprochaineWeek = dateP.setDate(dateP.getDate() + this.state.intervalle)
        dateprochaineWeek = dateFormat(dateprochaineWeek, "yyyy-mm-dd");
        this.verifPlages = true;
        this.dateFinFormat = new Date(this.dateFinFormat);
        this.dateFinFormat.setDate(this.dateFinFormat.getDate() + 1);
        this.dateDebutFormat = dateFormat(this.dateFinFormat, "yyyy-mm-dd");
        this.premiereDateDispo();
        this.dateDebut = dateDebutWeek;

    }
    handleSubmitHeure = (dateRdv, codePraticien, typeRdv) => {
        this.dateRdv = dateRdv;
        this.codePraticien = codePraticien;
        this.typeRdv = typeRdv;
        this.setState({ 'codePraticien': codePraticien, 'typeRdv': typeRdv, 'dateRdv': dateRdv }, function stateUpdateComplete() {
            this.setState({ 'show': true });
        });
    }
    onClickVoirPlusMoins = () => {
        if (this.plusMoins === "Voir plus") {
            this.setState({ 'showLigne': true }, function stateUpdateComplete() {
                this.plusMoins = "Voir moins"
                this.affichage(this.dateDebut)
            }.bind(this));
        } else {
            this.setState({ 'showLigne': false }, function stateUpdateComplete() {
                this.plusMoins = "Voir plus"
                this.affichage(this.dateDebut)
            }.bind(this));
        }
    }
    onClose = () => {
        this.setState({ 'show': false });
    }
    listePlagesDispo = (e) => {
        this.disabledMoveDatePrec = false;
        this.disabledMoveDateNext = false;
        this.verifPlages = true;
        var dateProchaine = dateFormat(this.state.prochainePriseRDV, "yyyy-mm-dd");
        this.dateDebutFormat = dateProchaine;
        this.plageDispo();
        this.affichage(this.state.prochainePriseRDV)
    }
    plageDispo() {
        var typeRdv = this.props.typeRdv;
        var codePraticien = this.props.codePraticien;
        var dateD = new Date(this.dateDebutFormat.replace(/-/g, "/"));
        var dateJour = new Date();
        var dateJourFormat = dateFormat(dateJour, "yyyy-mm-dd");
        if (this.state.dateDelai > dateJourFormat) {
            dateJourFormat = this.state.dateDelai;
        }
        this.verifJourDispo(dateD)
        this.loading = true;
        this.forceUpdate()
        var obj = this;
        axios.get(this.state.url + "/rdv/dispo/liste?dateDebut=" + this.dateDebutFormat + "&dateFin=" + this.dateFinFormat + "&codePraticien=" + codePraticien + "&typeRdv=" + typeRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {

                if (this.state.prochainePriseRDV == null || this.state.prochainePriseRDV == '')
                    this.aucuneDispo();
                this.dateJ = new Date();
                this.dateJ.setDate(this.dateJ.getDate() + this.state.intervalle);
                this.setState({ dateProchainRDVAffichage: dateFormat(this.state.prochainePriseRDV, "d mmmm yyyy") });
                this.dateD = this.dateDiff(this.dateJ, this.prochainePriseRDV)
                this.tabDate = response.data;
                if (this.dateDebutFormat <= dateJourFormat) {
                    this.disabledMoveDatePrec = true;
                } else {
                    this.disabledMoveDatePrec = false;
                }
                if (this.dateD.day >= 0 && this.verifPlages == false) {
                    this.affichage(this.state.prochainePriseRDV)
                    this.verifPlages = true;
                } else {
                    this.affichage(this.dateDebutFormat)
                }
            })
            .catch(function (error) {
                toast('Un problème est survenu.')
                obj.loading = false;
            });
    }
    aucuneDispo() {
        this.disabledMoveDatePrec = true;
        this.disabledMoveDateNext = true;
        this.headerTab = [];
        this.headerTab.push(<th className="align-text">Aucun créneau de libre n'a été trouvé, veuillez contacter le cabinet pour prendre rendez-vous.</th>);
        this.bodyTrTab = [];
        this.loading = false;
    }
    plusDispo() {
        this.disabledMoveDatePrec = false;
        this.disabledMoveDateNext = true;
        this.headerTab = [];
        this.bodyTrTab = [];
        this.bodyTrTab.push(<tr ><td colSpan={this.state.intervalle}> Il n'y a plus de disponibilité.</td></tr>);
        this.loading = false;
    }
    affichageHeure() {
        this.bodyTrTab = [];
        var nbMaxHeure = this.tabDate.nbMaxHeure;
        if (nbMaxHeure === undefined || nbMaxHeure === 0) {
            this.bodyTrTab = [];
            this.bodyTrTab.push(<tr ><td colSpan={this.state.intervalle}> Aucun résultat</td></tr>);
        } else {
            for (let indH = 0; indH < nbMaxHeure; indH++) {
                this.bodyTab = [];
                for (let ind in this.tabHeader) {
                    this.dateDebut = this.tabHeader[0];
                    var indexKey = this.tabDate.items.findIndex(obj => obj.date === this.tabHeader[ind]);
                    if (indexKey !== -1) {
                        if (this.tabDate.items[indexKey].heure[indH] !== undefined) {
                            this.bodyTab.push(<td><Horaire key={this.tabHeader[ind] + this.tabDate.items[indexKey].heure[indH]} codePraticien={this.state.codePraticien} typeRdv={this.state.typeRdv} date={this.tabHeader[ind]} heure={this.tabDate.items[indexKey].heure[indH]} handleSubmitHeure={this.handleSubmitHeure} /></td >);
                        } else {
                            this.bodyTab.push(<td className="align-text" > - </td>)
                        }
                    } else {
                        this.bodyTab.push(<td className="align-text" > - </td>)
                    }
                }
                if (indH >= 3) {
                    this.bodyTrTab.push(<tr key={indH} style={this.state.showLigne ? {} : { display: 'none' }}>{this.bodyTab}</tr>);
                } else {
                    this.bodyTrTab.push(<tr key={indH}>{this.bodyTab}</tr>);
                }
            }
        }
        if (nbMaxHeure >= 3) {
            this.bodyTrTab.push(<tr><td className="align-text" colSpan={this.state.intervalle} ><Button className="btn btn-monortho" onClick={this.onClickVoirPlusMoins}> {this.plusMoins} </Button></td></tr>)
        }
        this.loading = false;
    }
    inArray(needle, haystack) {
        var length = haystack.length;
        for (var i = 0; i < length; i++) {
            if (haystack[i] == needle) return true;
        }
        return false;
    }
    verifJourDispo(dateWeek) {
        var d = "";
        var m = "";
        var index = 0;
        this.tabHeader = [];
        this.headerTab = [];
        var dateDebut = dateWeek.setDate(dateWeek.getDate() + index);
        this.dateDebutFormat = dateFormat(dateDebut, "yyyy-mm-dd");
        for (this.ind = 0; this.ind < this.state.intervalle;) {
            dateWeek.setDate(dateWeek.getDate() + index);
            var dateF = dateFormat(dateWeek, "yyyy-mm-dd");
            d = dateFormat(dateWeek, "dddd");
            m = dateFormat(dateWeek, "dd mmm");
            if (this.inArray(d, this.state.jourDispo)) {
                this.tabHeader.push(dateF);
                this.headerTab.push(<th className="align-text">{d}<br />{m}</th>);
                this.ind++;
            }
            this.dateFinFormat = dateF
            index = 1;
        }
    }
    determineDateDebut(dateFin) {
        var d = "";
        var m = "";
        var index = 1;
        this.tabHeader = [];
        this.headerTab = [];
        for (this.ind = 0; this.ind < this.state.intervalle;) {
            dateFin.setDate(dateFin.getDate() - index);
            var dateF = dateFormat(dateFin, "yyyy-mm-dd");
            d = dateFormat(dateFin, "dddd");
            m = dateFormat(dateFin, "dd mmm");
            if (this.inArray(d, this.state.jourDispo)) {
                this.tabHeader.push(dateF);
                this.headerTab.push(<th className="align-text">{d}<br />{m}</th>);
                this.ind++;
            }
            this.dateDebutFormat = dateF
            index = 1;
        }
    }
    affichage(date) {

        if (date == null || date == '')
            this.aucuneDispo();
        this.ind = 0;
        this.bodyTrTab = [];
        if (this.state.prochainePriseRDV !== 0 && this.state.prochainePriseRDV !== null) {
            this.dateJ = new Date();
            this.dateJ.setDate(this.dateJ.getDate() + this.state.intervalle);
            this.dateJFormat = dateFormat(this.dateJ, "dd/mm/yyyy");
            this.dateD = this.dateDiff(this.dateJ, this.state.prochainePriseRDV)
            if (this.dateD.day >= 0 && this.verifPlages == false) {
                this.disabledMoveDatePrec = true;
                this.disabledMoveDateNext = true;
                this.bodyTrTab.push(<tr><td className="align-text" colSpan={this.state.intervalle} >Pas de disponibilité cette semaine | <a href="#link" role="button" onClick={this.listePlagesDispo}> Prochaine disponibilité le  {this.state.dateProchainRDVAffichage} </a></td></tr>)
                this.loading = false;
            } else {
                { this.affichageHeure() }
            }
        } else {
            this.aucuneDispo();
        }
        this.forceUpdate()
        this.render();
    }
    render() {
        return (<>
            < Row>
                <Col md={1} sm={1} >
                    <Button onClick={this.onPrecedentClick} className='btn btn-monortho' hidden={this.disabledMoveDatePrec}>&lt;</Button>
                </Col>
                <Col md={10} sm={10}>
                    {this.loading ? (
                        <ReactLoading className="align-text" type="spinningBubbles" color="#00b199" height={50} width={50} />
                    ) :
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {this.headerTab}
                                </tr>
                            </thead>
                            <tbody>
                                {this.bodyTrTab}
                            </tbody>
                        </table>
                    }
                </Col>
                <Col md={1} sm={1}>
                    <Button onClick={this.onSuivantClick} className='btn btn-monortho' hidden={this.disabledMoveDateNext}>&gt;</Button>
                </Col>
            </Row>
            <InfoModal
                handleShow="false"
                handleValidationModal={this.handleValidationModal}
                show={this.state.show} onClose={this.onClose}
                dateRdv={this.state.dateRdv}
                codePraticien={this.state.codePraticien}
                typeRdv={this.state.typeRdv}
                numeroSerie={this.state.numeroSerie}
                url={this.state.url}
                refreshHoraire={this.refreshHoraire}
                soft={this.state.soft}
                notice={this.state.notice}
            />
            <ModalSms
                showModalSms={this.state.showModalSms}
                onHideSms={this.onHideSms}
                handleValidationSms={this.handleValidationSms}
                dateRdv={this.state.dateRdv}
                telephone={this.state.telephone}
                paysTel={this.state.paysTel}
                typeRdv={this.state.typeRdv}
                idValidation={this.state.idValidation}
                numeroSerie={this.state.numeroSerie}
                codeInterne={this.state.codeInterne}
                url={this.state.url}
                codePraticien={this.state.codePraticien}
            />
            <ToastContainer position="top-center" />
            {this.state.RdvConfirme ? (
                <Navigate to='/RdvConfirme' />
            ) : <div></div>
            }
        </>
        )

    }
}
export default Planning
