import React from "react";
import Accueil from './Accueil'
import ChoixPraticien from './ChoixPraticien'
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Button } from 'react-bootstrap';
import InfoModal from './InfoModal'
import dateFormat from 'dateformat';
import Planning from './Planning';
import PlanningMobile from './PlanningMobile';
//import '../styles/Styles.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codePraticien: 0,
      typeRdv: "",
      prochainePriseRDV: 0,
      jourDispo: {},
      numeroSerie: props.numeroSerie,
      intervalle: 7,
      showPlanning: false,
      tailleEcran: 0,
      soft: "",
      delaiPremierRdv: 0,
      notice: ""
    }
    this.url = "";
    var location = window.location;
    if (location.origin.indexOf('monorthodontiste') > -1) {
      if (location.origin.indexOf('-dev') > -1) {
        this.url = location.protocol + '//api.moncabinetdentaire.eu' + this.url;
        //  this.url = location.protocol + '//api-dev.monorthodontiste.eu' + this.url;
      } else {
        this.url = location.protocol + '//api.monorthodontiste.eu' + this.url;
      }
    } else if (location.origin.indexOf('moncabinetdentaire') > -1) {
      if (location.origin.indexOf('-dev') > -1)
        // this.url = location.protocol + '//api-dev.moncabinetdentaire.eu' + this.url;
        this.url = location.protocol + '//api.moncabinetdentaire.eu' + this.url;
      else
        this.url = location.protocol + '//api.moncabinetdentaire.eu' + this.url;
    } else {
      this.url = location.protocol + '//api.monorthodontiste.eu' + this.url;
      // this.url = location.protocol + '//localhost:800' + this.url;
    }
    this.tailleEcran = window.innerWidth;
    this.setState({ tailleEcran: this.tailleEcran });
  }
  // Permet de récupérer le code praticien et le type de rdv grâce au composant choixPraticien
  handleSubmitPraticien = (codePraticien, typeRdv, soft, delaiPremierRdv, notice) => {
    this.setState({ tailleEcran: this.tailleEcran });
    this.setState({ codePraticien: codePraticien, typeRdv: typeRdv, tailleEcran: this.tailleEcran, soft: soft, delaiPremierRdv: delaiPremierRdv, notice: notice }, function stateUpdateComplete() {
      this.setState({ 'showPlanning': true }, function stateUpdateComplete() {
        if (this.state.tailleEcran > 1080) {
          this.refs.plan.getInfo(codePraticien, typeRdv, soft, delaiPremierRdv, notice);
        } else {
          this.refs.planMobile.getInfo(codePraticien, typeRdv, soft, delaiPremierRdv, notice);
        }
      }.bind(this));
    }.bind(this));
  }

  render() {
    return (
      <>
        <div className="bg-light"> <Accueil /><InfoModal />
          <br />

          <ToastContainer position="top-center" />
          <ChoixPraticien
            handleSubmitPraticien={this.handleSubmitPraticien}
            numeroSerie={this.state.numeroSerie}
            url={this.url}
          />
          {this.state.showPlanning ? (
            <div className="container border border-white bg-white cadreInfo"  >
              < Row>
                <Col md={12}>
                  {this.state.tailleEcran > 1080 ? (
                    <Planning ref="plan"
                      jourDispo={this.state.jourDispo}
                      key={this.state.codePraticien + this.state.typeRdv}
                      handleSubmitPraticien={this.handleSubmitPraticien}
                      prochainePriseRDV={this.state.prochainePriseRDV}
                      codePraticien={this.state.codePraticien}
                      typeRdv={this.state.typeRdv}
                      numeroSerie={this.state.numeroSerie}
                      url={this.url}
                      intervalle={this.state.intervalle}
                      soft={this.state.soft}
                      notice={this.state.notice}
                    />
                  ) : (
                    <PlanningMobile ref="planMobile"
                      jourDispo={this.state.jourDispo}
                      key={this.state.codePraticien + this.state.typeRdv + "Mobile"}
                      handleSubmitPraticien={this.handleSubmitPraticien}
                      prochainePriseRDV={this.state.prochainePriseRDV}
                      codePraticien={this.state.codePraticien}
                      typeRdv={this.state.typeRdv}
                      numeroSerie={this.state.numeroSerie}
                      url={this.url}
                      intervalle={this.state.intervalle}
                      soft={this.state.soft}
                      notice={this.state.notice}
                    />
                  )
                  }
                </Col>
              </Row>
            </div>
          ) :
            <div></div>
          }
        </div>
      </>
    );
  }
}
export default App;
