
import { Col, Row, Button, Form, Card } from 'react-bootstrap';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import dateFormat, { i18n } from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from 'react-bootstrap-dialog'
import { useParams } from "react-router-dom";
import ReactLoading from 'react-loading';

export default function Annulation() {
    const { codeInterne, numeroSerie } = useParams();
    const [dateRdv, setDateRdv] = useState(0);
    const [color, setColor] = useState("black");
    const [rdvAnnuler, setRdvAnnuler] = useState(0);
    const [loading, setLoading] = useState(1);

    var dialog = "";
    Dialog.setOptions({
        defaultOkLabel: 'Oui',
        defaultCancelLabel: 'Non',
        primaryClassName: 'btn-danger',
        defaultButtonClassName: 'btn-primary'
    })
    var location = window.location;
    var url = ""
    if (location.origin.indexOf('monorthodontiste') > -1) {
        if (location.origin.indexOf('-dev') > -1) {
            // url = location.protocol + '//api-dev.monorthodontiste.eu' + url;
            url = location.protocol + '//api.monorthodontiste.eu' + url;
        } else {
            url = location.protocol + '//api.monorthodontiste.eu' + url;
        }
    } else if (location.origin.indexOf('moncabinetdentaire') > -1) {
        if (location.origin.indexOf('-dev') > -1) {
            //     url = location.protocol + '//api-dev.moncabinetdentaire.eu' + url;            
            url = location.protocol + '//api.moncabinetdentaire.eu' + url;
        } else {
            url = location.protocol + '//api.moncabinetdentaire.eu' + url;
        }
    } else {

        url = location.protocol + '//api.monorthodontiste.eu' + url;
        // url = location.protocol + '//localhost' + url;
    }
    const annulationRdv = (e) => {
        e.preventDefault();
        axios.delete(url + "/rdv/cabinet/" + codeInterne + "?numero_serie=" + numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                if (response.data.data.success) {
                    //   toast("Le rendez-vous a été annulé.") 
                    setRdvAnnuler(1);
                    setLoading(false);
                } else {
                    toast("Un problème est survenu pour supprimer le rendez-vous")
                    setLoading(false);
                }
            })
            .catch(function (error) {
                toast("Un problème est survenu pour supprimer le rendez-vous")
                setLoading(false);
            });

    }

    i18n.dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    i18n.monthNames = ["janv.", "févr.", "mar.", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov", "déc.", "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre",];
    useEffect(() => {
        axios.get(url + "/rdv/cabinet/" + codeInterne + "?numero_serie=" + numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                if (response.data != false) {
                    if (response.data.data.data[0] != undefined) {
                        var dateR = new Date(response.data.data.data[0].rdv)
                        setDateRdv(dateFormat(dateR, "dddd dd mmmm yyyy à HH:MM"));
                        setRdvAnnuler(0);
                    } else {
                        setRdvAnnuler(1);
                    }
                    setLoading(false);
                } else {
                    setRdvAnnuler(2);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setRdvAnnuler(3);
                toast("Un problème est survenu");
                setLoading(false);
                console.log(error)
            });
    }, [])
    return (
        < div className="container border border-white cadreAnnulation" >
            < Row>
                <Col md={12} >
                    <Card className="text-center">
                        <Card.Header>Annulation</Card.Header>
                        <Card.Body>
                            {loading ? (
                                <ReactLoading className="align-text" type="spinningBubbles" color="#00b199" height={50} width={50} />
                            ) :
                                <Form onSubmit={annulationRdv}>
                                    <Form.Group as={Row} >
                                        <Row className="divAnnulation">
                                            {(() => {
                                                switch (rdvAnnuler) {
                                                    case 0:
                                                        return (
                                                            <div>
                                                                <Card.Title>  Voulez-vous vraiment annuler le rendez vous du <b> {dateRdv}</b> ?</Card.Title>
                                                                <Button className="btn btn-danger" id="btnAnnulation" type="submit">
                                                                    J'annule mon rendez-vous
                                                                </Button>
                                                            </div>
                                                        )

                                                    case 1:
                                                        return (
                                                            <Card.Title >  Votre rendez-vous a été annulé.</Card.Title>
                                                        )
                                                    case 2:
                                                        return (
                                                            <Card.Title > Vous ne pouvez plus annuler le rdv.</Card.Title>
                                                        )
                                                    case 3:
                                                        return (
                                                            <Card.Title > Le lien n'est pas valide.</Card.Title>
                                                        )
                                                }
                                            })()}
                                        </Row>
                                    </Form.Group>
                                </Form>
                            }
                        </Card.Body>
                        <Card.Footer className="text-muted"></Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Dialog ref={(component) => { dialog = component }} />
            < ToastContainer position="top-center" toastStyle={{ color: "red" }} />
        </div >
    )
} 