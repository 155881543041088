import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
class RdvConfirme extends React.Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
    }

    render() {
        return (<>
            <div className="container border border-white cadreAnnulation" >
                < Row>
                    <Col md={12} >
                        <Card className="text-center">
                            <Card.Header>Le rendez-vous a été validé</Card.Header>
                            <Card.Body>
                                <Row className="divAnnulation">
                                    <Col md={12}>
                                        Nous vous avons adressé la confirmation par SMS.
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-muted"></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}
export default RdvConfirme