import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './components/Main';
import RdvConfirme from './components/RdvConfirme';
import Annulation from './components/Annulation';
import AccordionJour from './components/AccordionJour';
import './var.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:numeroSerie" element={<Main />} />
      <Route path="/annulation/:codeInterne/:numeroSerie" element={<Annulation />} />
      <Route path="/RdvConfirme" element={<RdvConfirme />} />
      <Route path="/Accordeon" element={<AccordionJour />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
); 
