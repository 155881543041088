import React from "react";
import { Col, Row, Modal, Button, Form, Toast } from 'react-bootstrap';
import Select from 'react-select';
import dateFormat from 'dateformat';
import { i18n } from "dateformat";
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalSms from './ModalSms';
import axios from 'axios';
import '../styles/Modal.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import ReactLoading from 'react-loading';
import MyIcon from "./assets/images/logo.svg";
import ReactHtmlParser from "react-html-parser";
export default class infoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      codePraticien: this.props.codePraticien,
      typeRdv: this.props.typeRdv,
      dateRdv: this.props.dateRdv,
      loading: false,
      numeroSerie: this.props.numeroSerie,
      url: this.props.url,
      listeDrapeaux: {},
      options: [],
      valuePays: "",
      soft: this.props.soft,
      sexe: "",
      email: "",
      notice: this.props.notice
    };
    this.dateJ = new Date();
    this.dateJ = dateFormat(this.dateJ, "yyyy-mm-dd");
    this.listeNum = {}
    this.optionsDrapeaux = [];
    this.optionDrapeau = this.optionDrapeau.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleChangeSexe = this.handleChangeSexe.bind(this);
    this.handleChangePays = this.handleChangePays.bind(this);
    this.images = this.importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg)$/));


  }
  importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  onClose = e => {
    this.showModal = false
  };
  handleChangeSexe(e) {
    this.setState({
      sexe: e.currentTarget.value,
    });
    let fields = this.state.fields;
    fields["sexe"] = e.currentTarget.value;
    this.setState({ fields });
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number)
    return (isValidPhoneNumber)
  }
  componentDidMount() {
    this.optionDrapeau()
  }
  handleChangePays(event) {
    this.setState({ valuePays: event.value.num });
    var code = this.listeNum[event.value.num]
    this.setState({ selectPaysLabel: { value: event.value.num, label: <div><img src={this.images[`${code}.png`].default} height="20px" width="20px" /> </div> } })

  }
  optionDrapeau() {
    if (this.props.url != undefined) {
      axios.get(this.props.url + "/rdv/pays?numero_serie=" + this.state.numeroSerie, {
        headers: {
          'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
        }
      })
        .then((response) => {
          for (let index = 0; index < response.data.data.drapeaux.length; index++) {
            var code = response.data.data.drapeaux[index].code;
            var name = response.data.data.drapeaux[index].name;
            var num = response.data.data.drapeaux[index].num;
            this.listeNum[num] = code;
            if (this.images[`${code}.png`]) {
              this.optionsDrapeaux.push({
                value: { num }, label: <div><img src={this.images[`${code}.png`].default} height="15px" width="15px" /> - {name} </div>
              });
            }
          }
          this.setState({
            'options': this.optionsDrapeaux
          });
          this.setState({ selectPaysLabel: { value: "0033", label: <div><img src={this.images['FR.png'].default} height="20px" width="20px" /> </div> } })
          this.forceUpdate();
        })
        .catch(function (error) {
          toast.error('Un problème est survenu');
        });
    }
  }

  handleValidation(e) {
    e.preventDefault();
    this.state.loading = true;
    let fields = this.state.fields;
    fields['codePraticien'] = this.state.codePraticien;
    fields['typeRdv'] = this.state.typeRdv;
    fields['dateRdv'] = this.props.dateRdv;
    var obj = this;
    var valide = true;
    var message = "";
    if (fields['telephone'] === '' || fields['nom'] === '' || fields['prenom'] === '' || fields['dateNaissance'] === '' || fields['dateNaissance'] === undefined || fields['sexe'] === undefined) {
      message = 'Veuillez remplir tous les champs';
      valide = false;
    }
    if ((fields['email'] !== undefined) && (fields['email'] !== "")) {
      var regex = new RegExp('^(([^<>()[]\.,;:s@]+(.[^<>()[]\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$');
      if (!regex.test(fields['email'])) {
        message = 'Veuillez saisir une adresse mail valide';
        valide = false;
      }
    }
    if ((fields['dateNaissance'] !== undefined) && (fields['dateNaissance'].length == 11)) {
      message = 'Date de naissance invalide';
      valide = false;
    }
    if ((fields['telephone'] !== undefined) && (fields['telephone'] !== "")) {
      var regex = new RegExp('^0{0,1}[1-9][0-9]+$');
      if (!this.validatePhoneNumber(fields['telephone']) && valide == true || !regex.test(fields['telephone'])) {
        message = 'Veuillez saisir un numéro de téléphone correct';
        valide = false;
      }
    } else {
      message = 'Veuillez saisir un numéro de téléphone'
      valide = false;
    }
    if (message != "") {
      toast.warn(message);
    }

    fields['paysTel'] = this.state.selectPaysLabel.value
    if (valide == true) {
      fields['numero_serie'] = this.state.numeroSerie;
      axios.post(this.state.url + '/rdv/valider/creneau', fields, {
        headers: {
          'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
        }
      })
        .then(function (response) {
          var error = "";
          var code_error = "";
          var err = response.data.response;
          if (err != undefined) {
            code_error = response.data.response.errorCode;
          }
          switch (code_error) {
            case 7501:
              error = "La prise de rendez-vous en ligne est réservé aux nouveaux patients. Vous êtes déjà patient du cabinet, merci de passer par l'application Mon Orthodontiste.";
              break;
            case 0:
              error = "L'horaire n'est plus disponible";
              break;
            case 3:
              error = "La nature de rendez-vous n'est pas compatible avec le praticien";
              break;
            case 8430:
              error = "Veuillez remplir tous les champs";
              break;
            case 9501:
              error = "Vous devez renseigner tous les champs";
              break;
            case 9503:
              error = "L'horaire n'est plus disponible";
              break;
            case 9504:
              error = "La prise de rendez-vous en ligne est réservé aux nouveaux patients.\nMerci d’utiliser l’application Mon Orthodontiste.";
              break;
            case 9599:
              error = "Erreur, veuillez réésayer ultérieurement.";
              break;
            default:
              break;
          }
          if (error != "") {
            if (code_error == 0 || code_error == 9503) {
              toast.warn(error);
              obj.props.refreshHoraire();
              obj.onClose();
            } else {
              if (code_error == 9504) {
                toast(error, {
                  className: "iconMonOrtho",
                  icon: ({ theme, type }) => <img src={MyIcon} />
                });
              } else {
                toast.warn(error);
              }
            }
          }
          if (response.data.data.idValidation != undefined) {
            obj.state.loading = false;
            obj.onClose();
            obj.props.handleValidationModal(response.data.data.idValidation, fields['telephone'], fields['paysTel'], response.data.data.codeInterne, fields['email']);
          }
        })
        .catch(function (error) {
          toast.error('Un problème est survenu');
        });
      valide = true;
    } else {
      this.state.loading = false;
    }

  }
  render() {
    i18n.monthNames = [
      "janv.",
      "févr.",
      "mar.",
      "avr.",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov",
      "déc.",
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "aout",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    this.onClose = this.props.onClose;
    this.dateRdv = Date.parse(this.props.dateRdv);
    this.dateHeure = dateFormat(this.dateRdv, " d mmmm yyyy à HH:MM");
    if (!this.props.show) {
      return null;
    }
    this.showModal = this.props.show;
    this.checkM = false;
    this.checkN = false;
    this.checkF = false;
    switch (this.state.sexe) {
      case "M":
        this.checkM = true;
        break;
      case "F":
        this.checkF = true;
        break;
      case "N":
        this.checkN = true;
        break;
      default:
        break;
    }
    let neutre = "";
    if (this.state.soft != "OL") {
      neutre = <Form.Check
        className='font-small d-flex align-items-center'
        type="radio"
        label="Neutre"
        value="N"
        name="sexe"
        id="N"
        onChange={this.handleChangeSexe}
        checked={this.checkN}
        style={{
          marginLeft: "10px"
        }}
      />
    } else {
      neutre = "";
    }
    return (

      <>
        <Modal
          show={this.showModal}
          onHide={this.onClose}
          size="md"
          centered
          backdrop="static"
        >
          <Modal.Header style={{
            textAlign: "center",
          }}>
            <Modal.Title style={{
              textAlign: "center",
              flex: 1
            }}>Confirmez votre rendez-vous</Modal.Title>
            <Button variant="danger" size="sm" onClick={this.onClose}>X</Button>
          </Modal.Header>
          <Modal.Body className="bodyModal">
            <Form onSubmit={this.handleValidation.bind(this)}>
              <Row>
                <Col sm="12" xs="12" md="12"  >
                  Date du rendez-vous : <b>le {this.dateHeure}</b>
                </Col>
              </Row>
              <br />
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextNom">
                <Form.Label column sm="4">
                  Nom* :
                </Form.Label>
                <Col sm="8">
                  <Form.Control type="text" defaultValue={this.state.fields["nom"]} onChange={this.handleChange.bind(this, "nom")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPrenom">
                <Form.Label column sm="4">
                  Prénom* :
                </Form.Label>
                <Col sm="8">
                  <Form.Control type="text" defaultValue={this.state.fields["prenom"]} onChange={this.handleChange.bind(this, "prenom")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextDn">
                <Form.Label column sm="4">
                  Date de naissance* :
                </Form.Label>
                <Col sm="8">
                  <Form.Control type="date" max={this.dateJ} defaultValue={this.state.fields["dateNaissance"]} onChange={this.handleChange.bind(this, "dateNaissance")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextDn" >
                <Form.Label column sm="4">
                  Sexe* :
                </Form.Label>
                <Col sm="8" style={{
                  display: 'inline-flex'
                }}>
                  <Form.Check className='font-small d-flex align-items-center'
                    type="radio"
                    label="Féminin"
                    name="sexe"
                    id="F"
                    value="F"
                    checked={this.checkF}
                    onChange={this.handleChangeSexe}
                  />
                  <Form.Check className='font-small d-flex align-items-center'
                    type="radio"
                    label="Masculin"
                    name="sexe"
                    id="M"
                    value="M"
                    onChange={this.handleChangeSexe}
                    checked={this.checkM}
                    style={{
                      marginLeft: '10px'
                    }}
                  />
                  {neutre}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextTel">
                <Col md="4" sm="4" xs="12">
                  <Form.Label column>
                    Téléphone* :
                  </Form.Label>
                </Col>
                <Col md="3" sm="3" xs="5">
                  <Select value={this.state.selectPaysLabel} isSearchable={false} options={this.state.options} onChange={this.handleChangePays} />
                </Col>
                <Col md="5" sm="5" xs="7">
                  <Form.Control type="text" defaultValue={this.state.fields["telephone"]} onChange={this.handleChange.bind(this, "telephone")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="4">
                  Email :
                </Form.Label>
                <Col sm="8">
                  <Form.Control type="text" defaultValue={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} />
                </Col>
              </Form.Group>
              <Row>
                <Col sm="12" xs="12" md="12"  >
                  {ReactHtmlParser(this.state.notice)}
                </Col>
              </Row>
              <Modal.Footer style={{
                display: "flex",
                justifyContent: "center",
              }}>
                <Button variant="danger" id="btnAnnuler" onClick={this.onClose}>
                  Annuler
                </Button>
                <Button className="btn btn-monortho" onClick={this.valideRDV} type="submit">
                  Valider
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
        {
          this.state.loading ? (
            <ReactLoading className="align-text" type="spinningBubbles" color="#00b199" height={50} width={50} />
          ) : null
        }
        < ToastContainer position="top-center" />
      </>
    );
  }
}  