
import { Col, Row, Button, Accordion, Card } from 'react-bootstrap';
import dateFormat from 'dateformat';
import React from 'react';
import Horaire from './Horaire';
class AccordionJour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.items,
            dateJour: props.dateJour,
            heure: props.heure,
            typeRdv: props.typeRdv,
            codePraticien: props.codePraticien,
            typeRdv: props.typeRdv,

        };
        this.handleSubmitHeure = this.handleSubmitHeure.bind(this);
    }
    handleSubmitHeure(dateRdv, codePraticien, typeRdv) {
        this.props.handleSubmitHeure(dateRdv, codePraticien, typeRdv)
    }
    render() {
        this.dateJ = dateFormat(this.state.dateJour, "dddd dd mmmm");
        return (
            <div>
                <Accordion.Item eventKey={this.state.items}>
                    <Accordion.Header>{this.dateJ}</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            {this.state.heure.map((val, i) => {
                                return (<Col xs='4' sm='3' md='2' className="mb-2" ><Horaire
                                    key={this.state.dateJour + this.state.heure}
                                    codePraticien={this.state.codePraticien}
                                    typeRdv={this.state.typeRdv}
                                    date={this.state.dateJour}
                                    heure={val}
                                    handleSubmitHeure={this.handleSubmitHeure} /></Col>)
                            })}

                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </div >
        )
    }
}
export default AccordionJour;