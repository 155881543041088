import React from 'react';
import avatar from './assets/praticien.jpg';
import avatar2 from './assets/praticien2.jpg';
import avatar3 from './assets/praticien3.jpg';
import { Button, Col, Row } from 'react-bootstrap';
import Planning from './Planning';
import dateFormat from 'dateformat';
import axios from 'axios';
import Select from 'react-select';
import ReactLoading from 'react-loading';
class ChoixPraticien extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prochainePriseRDV: null,
            codePraticien: "",
            typeRdv: "",
            numeroSerie: props.numeroSerie,
            url: props.url,
            valuePraticien: { label: "Sélectionnez", value: '' },
            valueTypeRdv: { label: "Sélectionnez", value: '' },
            soft: '',
            showSearch: true,
            delai: 0,
            cabinet: ""
        };
        this.loading = false;
        this.typeRdv = "";
        this.handleChange = this.handleChangeType.bind(this);
        this.handleSubmitPraticien = this.handleSubmitPraticien.bind(this);
        this.handleChangePraticien = this.handleChangePraticien.bind(this);
        this.handleSubmit = this.props.handleSubmit;
        this.listePraticien = [];
        this.listeType = [];
        this.listeDelaiPremierRdv = [];
        this.listeNoticeParPraticien = [];
    }
    componentDidMount() {
        this.listeRdvPraticien()
    }
    // Permet de récupérer la liste des praticiens et des types de rdv
    listeRdvPraticien() {
        this.loading = true;
        var obj = this;
        var nouveauRdv = 1;
        var valPraticien = {};
        var valTypeRdv = "";
        var nbPraticiens = 0;
        var nbTypeRdv = 0;
        var codePraticien = "";
        var typeRdv = "";
        axios.get(this.props.url + "/rdv/praticiens?nouveauRdv=" + nouveauRdv + "&numero_serie=" + this.state.numeroSerie, {
            headers: {
                'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
            }
        })
            .then((response) => {
                nbPraticiens = response.data.data.length;
                var cabinet = response.data.data[0].id_cabinet.nom
                if (cabinet !== undefined) {
                    obj.setState({ cabinet: cabinet });
                }

                var soft = "";
                for (let index = 0; index < nbPraticiens; index++) {
                    const praticien = response.data.data[index];
                    this.listePraticien.push({ value: praticien.code_praticien, label: praticien.id_praticien.prenom + " " + praticien.id_praticien.nom }) //element["code_praticien"]; 
                    valPraticien = { value: praticien.code_praticien, label: praticien.id_praticien.prenom + " " + praticien.id_praticien.nom };
                    codePraticien = praticien.code_praticien;
                    soft = praticien.id_cabinet.soft;
                    this.listeDelaiPremierRdv.push({ code: praticien.code_praticien, valeur: praticien.rdv_premier_delai })
                    this.listeNoticeParPraticien.push({ code: praticien.code_praticien, notice: praticien.notice })
                }

                obj.loading = false;
                if (nbPraticiens == 1) {
                    obj.setState({ 'codePraticien': codePraticien });
                    obj.setState({ valuePraticien: valPraticien });
                }
                obj.setState({ 'soft': soft });
                axios.get(this.props.url + "/rdv/typesrdv/liste?nouveauRdv=" + nouveauRdv + "&numero_serie=" + this.state.numeroSerie, {
                    headers: {
                        'X-AUTH-TOKEN': global.headers["X-AUTH-TOKEN"]
                    }
                })
                    .then((response) => {
                        var i = 0;
                        for (let index = 0; index < response.data.data.length; index++) {
                            const type_rdv = response.data.data[index];
                            if (type_rdv.nom != "") {
                                this.listeType.push({ value: type_rdv.code_orthokis, label: type_rdv.nom });
                                valTypeRdv = { value: type_rdv.code_orthokis, label: type_rdv.nom };
                                typeRdv = type_rdv.code_orthokis
                                nbTypeRdv++;
                            }
                        }
                        this.forceUpdate()
                        if (nbTypeRdv == 1) {
                            obj.setState({ 'typeRdv': typeRdv });
                            obj.setState({ valueTypeRdv: valTypeRdv });
                        }
                        if (nbTypeRdv == 1 && nbPraticiens == 1) {
                            this.handleSubmitPraticien();
                            obj.loading = false;
                        }
                        if (nbTypeRdv == 0 || nbPraticiens == 0) {
                            obj.setState({ showSearch: false });
                        }
                    })
                    .catch(function (error) {
                        obj.loading = false;
                    });
            })
            .catch(function (error) {
                obj.loading = false;
            });

    }
    handleChangePraticien(val) {
        var codePraticien = val.value;
        var labelPraticien = val.label;
        this.setState({ 'codePraticien': val.value });
        this.setState({ 'valuePraticien': { value: codePraticien, label: labelPraticien } });
        var index = this.listeDelaiPremierRdv.findIndex(item => item.code === val.value)
        if (index != -1) {
            this.setState({ 'delai': this.listeDelaiPremierRdv[index].valeur });
        }
        var indexNotice = this.listeNoticeParPraticien.findIndex(item => item.code === val.value)
        if (indexNotice != -1) {
            this.setState({ 'notice': this.listeNoticeParPraticien[indexNotice].notice });
        }

    }
    handleChangeType = (val) => {
        var valueTypeRdv = val.value;
        var labelTypeRdv = val.label
        this.setState({ 'typeRdv': val.value });
        this.setState({ 'valueTypeRdv': { value: valueTypeRdv, label: labelTypeRdv } });
    }
    // pour appeler app.js avec les data praticien et typeRDV et le deployer dans le composant planning
    handleSubmitPraticien() {
        this.props.handleSubmitPraticien(this.state.codePraticien, this.state.typeRdv, this.state.soft, this.state.delai, this.state.notice)
    }
    render() {
        var obj = this;
        return (<>
            <div className="container border border-white bg-white cadreInfo" >
                <Row >  <Col><p>{this.state.cabinet}</p></Col></Row>
                {this.loading ? (
                    <Row> <Col><ReactLoading className="align-text" type="spinningBubbles" color="#00b199" height={50} width={50} /> </Col></Row>
                ) : (
                    <Row>
                        <Col>
                            {this.state.showSearch ? (

                                <Row >
                                    <Col md={1} sm={0} >
                                    </Col>
                                    <Col md={5} sm={5} >
                                        <label><b>Praticien :</b></label>
                                        <Select isSearchable={false} options={this.listePraticien}
                                            value={obj.state.valuePraticien}
                                            noOptionsMessage={() => "Aucun résultat"}
                                            onChange={this.handleChangePraticien} placeholder="Sélectionnez" />
                                    </Col>
                                    <Col md={5} sm={5} >
                                        <label><b>Type de rendez-vous : </b></label>
                                        <Select options={this.listeType} isSearchable={false} onChange={this.handleChangeType}
                                            value={obj.state.valueTypeRdv}
                                            noOptionsMessage={() => "Aucun résultat"}
                                            placeholder="Sélectionnez" />
                                    </Col>
                                    <Col md={1} sm={1} className="mt-auto" >
                                        <br />
                                        <Button onClick={this.handleSubmitPraticien} className="btn btn-monortho" >Valider</Button>
                                    </Col>
                                </Row>
                            ) : (
                                <Col md={12} sm={12} >
                                    <b>La prise de rendez-vous en ligne n'est pas accessible pour le moment, veuillez contacter le cabinet.</b>
                                </Col>
                            )
                            }
                        </Col>
                    </Row>
                )
                }
            </div>
        </>
        );
    }
}
export default ChoixPraticien