import React from 'react';
import App from './App';
import { useParams } from "react-router-dom";
export default function Main() {
  const { numeroSerie } = useParams();
  return (
    <>
      <div className="bg-light"> <App numeroSerie={numeroSerie} />  </div>
    </>
  )
} 
